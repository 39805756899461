export const columns = [
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '180px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'status',
		label: '续签状态',
		width: '140px',
	},
	{
		prop: 'contractStartDate',
		label: '合同开始时间',
		width: '240px',
	},
	{
		prop: 'contractEndDate',
		label: '合同结束时间',
		width: '240px',
	},
	{
		prop: 'contractPeriod',
		label: '续签期数',
		width: '120',
	},
	{
		prop: 'monthlyRent',
		label: '合同月租',
		width: '150px',
	},
	{
		prop: 'createUser',
		label: '创建人',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '240px',
	},
	{
		prop: 'updateTime',
		label: '更新时间',
		width: '240px',
	},
	{
		prop: 'file',
		label: '合同附件',
		width: '120px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择合同开始时间',
			label: '合同开始时间',
			attr: 'contractStartDate',
			type: 'date',
		},
		{
			placeholder: '请选择续签状态',
			label: '续签状态',
			attr: 'status',
			type: 'select',
			options: [],
		},
	],
}

export const form = [
	{
		prop: 'driverNo',
		label: '司机ID',
		component: 'el-input',
		attrs: { placeholder: '请输入司机ID' },
	},
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'name',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机姓名' },
	},
	{
		prop: 'mobile',
		label: '手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机手机号' },
	},
	{
		prop: 'idCard',
		label: '身份证号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证号' },
	},
	{
		prop: 'sex',
		label: '性别',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择性别' },
	},
	{
		prop: 'idCardAddress',
		label: '身份证地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证地址' },
	},
	{
		prop: 'birthday',
		label: '出生日期',
		required: true,
		custom: true,
	},
	{
		prop: 'presentAddress',
		label: '现居住地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入现居住地址' },
	},
	{
		prop: 'contactPersonName',
		label: '紧急联系人',
		component: 'el-select',
		options: [],
	},
	{
		prop: 'contactPersonMobile',
		label: '联系人手机',
		component: 'el-input',
		attrs: { placeholder: '请输入联系人手机' },
	},
	{
		prop: 'drivingLicenseDate',
		label: '驾驶证日期',
		custom: true,
	},
]

export const uploadForm = [
	{
		prop: 'idCardPic',
		required: true,
		label: '身份证',
		custom: true,
	},
	{
		prop: 'drivingLicensePic',
		label: '驾驶证',
		component: 'yi-file-uploader',
		required: true,
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传驾驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'certificateProfessionalPic',
		label: '从业资格证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传从业资格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
