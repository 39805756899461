<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">续签记录</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					autoWidth
				>
					<template #status="{ value }">
						<span>{{ mapDict('approve_status', value) }}</span>
					</template>
					<template #file="{ row }">
						<span
							v-if="Array.isArray(row.annexesArr) && row.annexesArr.length"
							class="click"
							@click="onShowFile(row)"
						>
							查看
						</span>
						<span v-else>--</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<el-dialog
			v-model="visible"
			width="600px"
			class="container"
			title="合同附件"
			:before-close="onClose"
			:close-on-click-modal="false"
			append-to-body
			destroy-on-close
		>
			<div v-if="annexes.length">
				<div
					style="width: 400px; margin-bottom: 10px"
					class="click"
					v-for="(item, index) in annexes"
					:key="index"
					@click="onClick(item.url)"
				>
					{{ item.fileName }}
				</div>
			</div>
			<div v-else>暂无数据</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="onClose">取消</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'RenewalList',
})
</script>

<script setup name="RenewalList">
import { inject, ref, onMounted } from 'vue'
import { setting, columns } from './config'
import { useState, useFetch, useDict } from '@/utils/hooks.js'

import { renewalList } from '@/api/driver.js'

const $message = inject('$message')

const settingRef = ref(setting)

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [visible, setVisible] = useState(false)
const [annexes, setAnnexes] = useState([])
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = renewalList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

async function onShowFile(row) {
	setAnnexes(
		row.annexesArr.map(url => {
			const lastIndex = url.lastIndexOf('/')
			const fileName = url.substr(lastIndex + 1)
			return {
				fileName,
				url,
			}
		}),
	)
	setVisible(true)
}
function onClose() {
	setVisible(false)
}

function onClick(url) {
	window.open(url, '_blank')
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	setParams(event)
	fetchList()
}

async function fetchDict() {
	const dict = await useDict(['approve_status'])
	if (dict) setDict(dict)
	const status = findItem('status')
	status.options = dict.approve_status
}
function init() {
	fetchDict()
	fetchList()
}
// onActivated(() => {
// 	init()
// })
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
